<template>
  <div class="home">
    <v-row justify="center" align="center">
      <v-col cols="12" md="8" lg="5">
        <div class="text-center my-4" v-if="debug">
          <v-btn color="primary" @click="simulate">Simulate Scan</v-btn>
        </div>

        <span class="pulse mt-5 mb-3 mx-auto"></span>

        <h1 class="font-weight-light text-blue text-h5 text-center">
          Esperando escaner
        </h1>

        <v-divider inset></v-divider>

        <v-timeline>
          <v-timeline-item
            small
            right
            class="mb-c"
            v-for="(entry, i) in entries"
            :key="'pn-' + i"
          >
            <template v-slot:opposite>
              <span class="headline font-weight-bold primary--text"
                ><qrcode-vue :value="entry.qrCode" :size="130"></qrcode-vue
              ></span>
            </template>
            <div class="py-4">
              <h2 class="headline font-weight-light mb-4 primary--text">
                {{ entry.partNumber }}
              </h2>
              <h3>
                {{ entry.recordedAt }}
              </h3>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import QrcodeVue from "qrcode.vue";

export default {
  name: "Home",
  components: { QrcodeVue },
  data: () => ({
    entries: [],
  }),
  computed: {
    debug() {
      let debug = false;

      if (this.$route.query.debug !== undefined) debug = true;

      return debug;
    },
  },
  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    this.$barcodeScanner.destroy();
  },
  methods: {
    simulate() {
      this.insertEntry("0000000000");
    },
    insertEntry(barcode) {
      let recordedAt = moment();

      let entry = {
        partNumber: barcode,
        recordedAt: recordedAt.format("ddd DD, MMM YYYY @ HH:mm:ss"),
        qrCode: barcode + " " + recordedAt.format("YYYYMMDD HH:mm:ss"),
      };

      this.entries.unshift(entry);
    },
    onBarcodeScanned(barcode) {
      this.insertEntry(barcode);
    },
    resetBarcode() {
      let barcode = this.$barcodeScanner.getPreviousCode();
      console.log(barcode);
    },
  },
};
</script>

<style>
.text-blue {
  color: #167ad8;
}

.pulse {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #167ad8;
  cursor: pointer;
  box-shadow: 5 5 5 rgba(7, 95, 236, 0.4);
  animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(7, 95, 236, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(7, 95, 236, 0.4);
    box-shadow: 0 0 0 0 rgba(7, 95, 236, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.mb-c {
  margin-bottom: 40px;
}

.home .v-divider {
  border: 1px solid #a3d3ff !important;
  margin: 30px 0 30px;
}
</style>
